import React from "react";
import { array, string } from "prop-types";
import LargeFeaturesV1_5 from "./LargeFeaturesV1-5";
import Heading from "../../components-v2/Base/HeadingBuilder";

const GatedSpecial = (props) => {
  const { sectionData, title } = props;
  return (
    <div className="special-large w-full">
      <Heading
        level={2}
        injectionType={1}
        withBlueSymbol
        symbol="."
        className="text-center mobile-hidden"
      >
        {title || "What you get"}
      </Heading>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <LargeFeaturesV1_5 isSplit sectionData={sectionData} />
      <div className="circle-dark" />
    </div>
  );
};

GatedSpecial.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sectionData: array,
  title: string,
};

GatedSpecial.defaultProps = {
  sectionData: [],
  title: null,
};

export default GatedSpecial;
