import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import Image from "../ImageQuerys/LargeFeaturesImages";
import CtaSecondary from "../Buttons/secondary-cta";
import CtaPrimary from "../Buttons/primary-cta";
import check from "../../images/svg/checkmark.svg";
// import BusinessTypes from '../BusinessTypes/BusinessTypes';
import LargeFeaturesStyles from "../LargeFeatures/LargeFeaturesStyles";
import GatedStyles from "./GatedStyles";

const { Title } = Typography;

const LargeFeaturesV1_5 = (props) => {
  const { sectionData, style, customItem, isSplit } = props;
  const { sectionTitle, sectionNavId, featureBlocks } = sectionData;
  return (
    <section className="large-features" id={sectionNavId} style={style}>
      {sectionTitle && (
        <Title className="text-center large-features__main-title">
          {sectionTitle}
          <span className="blue_dot">.</span>
        </Title>
      )}
      {featureBlocks.map((block, idx) => (
        <Row
          key={`${idx.toString()}__block`}
          align="middle"
          justify="center"
          gutter={[32, 32]}
          className="blocks"
          id={block.id || ""}
        >
          <Col
            lg={{
              span: isSplit ? 12 : 24,
            }}
            xs={24}
          >
            {block.blockImg ? (
              <Image
                imageName={block.blockImg}
                className="large-features__main-image"
              />
            ) : (
              customItem
            )}
          </Col>
          <Col
            lg={{
              span: isSplit ? 12 : 24,
            }}
            sm={24}
            xl={{
              span: isSplit ? 12 : 24,
            }}
          >
            <div className="large-features__info">
              {block.blockTitle && (
                <Title
                  level={2}
                  className={`new-line ${
                    block.hideDesktop ? "hide-desktop" : ""
                  }`}
                >
                  {block.blockTitle}
                  <span className="blue_dot">.</span>
                </Title>
              )}
              {block.blockSubTitle && (
                <p className="large-features__subtitle">
                  {block.blockSubTitle}
                </p>
              )}
              {block.blockList && (
                <ul className="xl:max-w-[400px]">
                  {block.blockList.map((item, i) => (
                    <li key={`${i.toString()}__list`}>
                      <img src={check} alt="" />
                      <p>{item}</p>
                    </li>
                  ))}
                </ul>
              )}
              {block?.ctaInfo && (
                <CtaSecondary
                  ctaTitle={block.ctaInfo.ctaTitle}
                  target={block.ctaInfo.ctaTarget}
                />
              )}
              {block.ctaPrime && (
                <CtaPrimary
                  ctaTitle={block.ctaPrime.ctaTitle}
                  target={block.ctaPrime.ctaTarget}
                />
              )}
            </div>
          </Col>
        </Row>
      ))}
      <LargeFeaturesStyles />

      <GatedStyles />
    </section>
  );
};

LargeFeaturesV1_5.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  customItem: PropTypes.any,
  isSplit: PropTypes.bool,
};
LargeFeaturesV1_5.defaultProps = {
  sectionData: "",
  style: {},
  customItem: "",
  isSplit: false,
};

// eslint-disable-next-line camelcase
export default LargeFeaturesV1_5;
