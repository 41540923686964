import React from "react";
import { viewports, colors } from "../../style-vars";

const { xsOnly, smOrSmaller, mdOrSmaller, mdOrBigger } = viewports;
const { white, black } = colors;

const GatedStyles = () => (
  <style jsx>{`
    .gate-heading {
      padding: 128px 32px 32px 32px;
    }
    .gate-heading-v1 {
      padding: 128px 32px 32px 32px;
      background: #08031d;
      margin-left: 0 !important;
    }
    .cta-light {
      color: ${white} !important;
    }
    .big-gated-intro {
      font-size: 42px !important;
      font-weight: 800;
      max-width: 75%;
      line-height: 1.2;
    }
    .big-gated-intro-full {
      font-size: 42px !important;
      font-weight: 800;
      max-width: 100%;
      line-height: 1.4;
    }
    .gated-intro-para {
      font-size: 20px;
      color: #748093;
      line-height: 1.4;
    }
    .gated-form-cont {
      background: ${white};
      padding: 24px 8px;
      border-radius: 16px;
      margin: 6%;
      position: fixed;
      right: 6%;
      width: 40%;
      box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
    }
    .gated-heading-v3 {
      padding: 24px;
    }
    .gated-form-cont-v3 {
      background: ${white};
      padding: 24px 8px;
      border-radius: 16px;
      margin: 10% 10% 10% 0;
      position: relative;
      box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
      z-index: 9;
    }
    .asset-download {
      position: absolute;
      left: 50%;
      top: -100px;
      width: 100px;
      transform: translate(-50%, 0px);
    }
    .form-bg {
      position: absolute;
      z-index: 1;
      width: 105%;
      left: 0%;
      top: 0;
    }
    .gated-cta {
      width: 100%;
      background: #08031d;
      padding: 100px;
      position: relative;
      overflow: hidden;
      border-radius: 32px;
      margin-bottom: 32px;
    }
    .gated-title {
      color: ${white};
      font-size: 38px;
      width: 50%;
      font-weight: 800;
      line-height: 1.4;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .gated-cta-img {
      position: absolute;
      right: -10%;
      top: -10%;
      width: 50%;
    }
    .special-heading {
      font-size: 72px;
      text-align: center;
      font-weight: 800;
      line-height: 1.4;
    }
    .special-large {
      background: #f3f4f6;
      padding: 100px 32px;
      position: relative;
      overflow: hidden;
    }

    .circle-dark {
      background: #090d12;
      width: 530px;
      height: 530px;
      border-radius: 530px;
      position: absolute;
      right: -15%;
      bottom: -20%;
    }

    .gated-selling {
      padding: 100px 20% 0 20%;
    }
    .selling-subtext {
      text-align: center;
      color: #445469;
      font-size: 16px;
      margin-bottom: 40px;
    }
    .fake-center {
      display: table;
      margin: 0 auto;
    }
    .banner-text {
      width: 100%;
    }
    .fixed-padding {
      padding: 32px;
    }
    .form-btn-cont {
      margin: 0 auto;
      display: table;
    }
    @media ${mdOrBigger} {
      .hide-desktop {
        display: none;
      }
    }

    @media screen and (max-width: 1378px) {
      .circle-dark {
        width: 450px;
        height: 450px;
      }
    }
    @media screen and (max-width: 1280px) {
      .circle-dark {
        width: 400px;
        height: 400px;
      }
    }

    @media screen and (max-width: 1024px) {
      .circle-dark {
        display: none;
      }
      .gate-heading-v3 {
        padding: 16px;
        max-width: 100%;
      }
      .big-gated-intro {
        max-width: 100%;
        line-height: 1.4;
      }
    }

    @media ${mdOrSmaller} {
      .gated-form-cont {
        position: relative;
        left: 0;
        top: 32px;
        width: 94%;
        display: table;
        margin: 64px auto;
        right: auto !important;
      }

      .gate-heading-v1 {
        padding: 32px 32px 32px 32px;
        background: #08031d;
      }
      .big-gated-intro {
        font-size: 28px !important;
        font-weight: 800;
        max-width: 100%;
        line-height: 1.3;
      }
      .big-gated-intro-full,
      .special-heading {
        font-size: 48px !important;
        font-weight: 800;
        max-width: 100%;
        line-height: 1.4;
      }
      .gated-title {
        font-size: 38px;
        width: 50%;
        padding-bottom: 0;
      }
      .gated-cta {
        padding: 48px;
        max-height: 650px;
      }
      .gated-cta-img {
        position: relative;
        transform: translate(50%, 10%);
        width: 80%;
      }
      .gated-form-cont-v3 {
        margin: 10%;
      }
      .gate-heading {
        padding: 32px;
      }
    }

    @media ${smOrSmaller} {
      .gated-title {
        font-size: 38px;
        width: 100%;
      }
      .gate-heading {
        padding: 16px;
      }
      .gate-heading-v3 {
        padding: 16px;
      }
      .gated-cta {
        padding: 32px;
      }
      .gated-cta-img {
        transform: translate(20%, 20%);
        width: 100%;
      }
      .gated-intro-para {
        margin-bottom: 48px;
      }
      .fixed-padding {
        padding: 0;
      }
      .big-gated-intro-full {
        font-size: 36px !important;
      }
      .mobile-hidden {
        display: none;
      }
      .hide-desktop {
        display: block !important;
      }

      .gated-selling {
        padding: 16px;
      }
      .big-gated-intro-full,
      .special-heading {
        font-size: 36px !important;
        font-weight: 800;
        max-width: 100%;
        line-height: 1.4;
      }
      .gated-form-cont-v3 {
        margin: 0;
        max-width: 92%;
        margin: auto;
      }
      .special-large {
        padding: 32px 16px;
      }
      .form-btn-cont {
        margin: auto;
        display: block;
      }
      .large-features {
        padding: 16px;
      }
    }
  `}</style>
);

export default GatedStyles;
