import React from "react";
import { viewports, colors } from "../../style-vars";

const { smOrSmaller, mdOrSmaller, mdOrBigger } = viewports;
const { black, black200 } = colors;

const LargeFeaturesStyles = () => (
  <style jsx global>{`
    .large-features {
      position: relative;
      padding: 0 100px;
    }
    @media ${mdOrBigger} {
      .large-features__main-title {
        max-width: 80%;
        margin: auto !important;
      }
    }
    @media ${smOrSmaller} {
      .large-features {
        padding: 0 32px;
      }
    }
    .large-features .blocks {
      margin: 70px 0;
    }
    .large-features__main-title {
      margin: 40px 0;
    }
    .large-features .cta-secondary {
      display: flex;
    }

    .large-features__listsub {
      display: inline-block !important;
      font-weight: 400 !important;
      color: ${black200} !important;
    }
    @media ${mdOrSmaller} {
      .large-features__info p {
        margin-right: 0 !important;
      }
    }
    .large-features__info h2 {
      font-weight: 800 !important;
      margin-bottom: 24px !important;
      margin-right: 10%;
      font-size: 38px !important;
      line-height: 1.5 !important;
      max-width: 100%;
    }
    @media ${mdOrSmaller} {
      .large-features__info h2 {
        max-width: 100%;
        margin: auto;
      }
    }
    .large-features__info h3 {
      margin-top: 32px !important;
      color: ${black} !important;
      font-size: 32px;
      margin-bottom: 16px;
    }
    .large-features__info h4 {
      font-size: 72px !important;
      color: ${black} !important;
    }
    .large-features__info p {
      line-height: 2;
      color: ${black200};
      font-size: 16px;
      margin-bottom: 32px;
    }
    .large-features__info ul {
      list-style: none;
      padding: 0;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .large-features__info ul li {
      margin: 18px 0;
      display: flex;
      align-items: flex-start;
    }
    .large-features__info ul li img {
      margin-right: 12px;
    }
    .large-features__info ul li p {
      font-size: 16px;
      font-weight: 800;
      line-height: 1.2;
      margin-bottom: 0;
    }
    .large-features__subtitle {
      margin: auto;
    }
    @media ${mdOrSmaller} {
      .large-features__subtitle {
        margin: auto;
      }
    }
    .large-recruit {
      padding: 0 32px;
      position: relative !important;
    }
    @media ${mdOrSmaller} {
      .large-recruit {
        padding: 0;
        margin-top: 0 !important;
      }
    }
    .large-recruit__blocks {
      z-index: 99;
      padding: 50px;
      background: #f3f4f6;
      border-radius: 24px !important;
    }
    .large-recruit__blocks p {
      line-height: 2;
      color: ${black200};
      font-size: 16px;
      margin-right: 15% !important;
      margin-bottom: 32px;
    }
    .large-recruit__badge {
      position: absolute;
      top: 60%;
      left: 35% !important;
    }
    .large-recruit__main-title {
      margin: 40px 0 60px 0 !important;
    }
    .fat-title {
      color: rgba(255, 255, 255, 0.5) !important;
      position: absolute;
      font-weight: 900;
      font-size: calc((75vw - 5rem) / 7);
      left: 50%;
      top: 0;
      z-index: 0;
      transform: translateY(50px) translateX(-47%);
      width: 100%;
    }
    @media ${mdOrSmaller} {
      .fat-title {
        display: none;
      }
    }
  `}</style>
);

export default LargeFeaturesStyles;
